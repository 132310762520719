import C = require("Everlaw/Constants");
import Dialog = require("Everlaw/UI/Dialog");
import Dom = require("Everlaw/Dom");
import Is = require("Everlaw/Core/Is");
import dojo_topic = require("dojo/topic");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import Str = require("Everlaw/Core/Str");
import Util = require("Everlaw/Util");
import Widget = require("Everlaw/UI/Widget");
import dojo_cookie = require("dojo/cookie");
import domForm = require("dojo/dom-form");
import { objectToQuery } from "Everlaw/Core/Obj";
import dojo_on = require("dojo/on");
import { getActiveRecommendation } from "Everlaw/SmartOnboarding/RecommendationSharedVariables";
import { DependencyList, useCallback, useEffect, useState } from "react";

export interface Callback {
    (data: any, msg?: string, stats?: any): void;
}

export type Fetcher = (url: string, content?: any) => Promise<any>;

export const get: Fetcher = (url: string, content?: any) => {
    return new Promise<any>(function retry(resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url + "?" + objectToQuery(content));
        prepareXHR(xhr, url, resolve, reject, () => {
            retry(resolve, reject);
        });
        xhr.send();
    });
};

export const post: Fetcher = (url: string, content?: any) => {
    return new Promise<any>(function retry(resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        prepareXHR(xhr, url, resolve, reject, () => {
            retry(resolve, reject);
        });
        xhr.send(objectToQuery(Object.assign({ _csrf: dojo_cookie("XSRF-TOKEN") }, content)));
    });
};

type UseRequestReturn<D, E> = {
    /**
     * Whether the request is still doing its initial load. This will be `true` until the request is
     * resolved or rejected. Subsequent refreshes/reloads will not change this value.
     */
    isLoading: boolean;
    /**
     * The data returned by the request. This will be `null` until the request is resolved.
     */
    data: D | null;
    /**
     * The error returned by the request. This will be `null` unless the request fails.
     */
    error: E | null;
    /**
     * Manually refreshes the request. Calling this is equivalent to changing a value in the
     * dependencies of `useRequest`. This will cause a new request to be made, even if the URL,
     * content, and dependencies are unchanged. This function will remain the same between renders,
     * so it is safe to include in the dependencies of hooks like `useEffect`.
     */
    refresh: () => void;
};

/**
 * React hook for making a network request for a generic promise instead of an explicit request URL.
 * Ends up calling {@link useRequest} with url and content ignored.
 */
export function usePromise<D = unknown, E = unknown>(
    action: () => Promise<D>,
    dependencies: DependencyList = [],
    onResolved: (result: D) => void = () => {},
    onRejected: (result: E) => void = () => {},
): UseRequestReturn<D, E> {
    return useRequest(action, "", undefined, dependencies, onResolved, onRejected);
}

/**
 * React hook for making a network request. A new request will be made when `url` or any value in
 * `dependencies` changes. The response of any previous request is ignored. `dependencies` should be
 * added to signal when `content` has logically changed.
 * @param fetcher Typically either {@link get Rest.get} or {@link post Rest.post}.
 * @param url URL to send the request to.
 * @param content Content to send with the request.
 * @param dependencies Array of values that will trigger a new request when any of its contents
 * change. Checks for changes using {@link Object.is} on each value.
 * @param onResolved Called when an up-to-date request is resolved.
 * @param onRejected Called when an up-to-date request is rejected.
 */
export function useRequest<D = unknown, E = unknown>(
    fetcher: Fetcher,
    url: string,
    content?: Record<string, unknown>,
    dependencies: DependencyList = [],
    onResolved: (result: D) => void = () => {},
    onRejected: (result: E) => void = () => {},
): UseRequestReturn<D, E> {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<D | null>(null);
    const [error, setError] = useState<E | null>(null);
    const [refreshCount, setRefreshCount] = useState<number>(0);
    const refresh = useCallback(() => setRefreshCount((count) => count + 1), []);

    useEffect(() => {
        let cancelled = false;

        fetcher(url, content).then(
            (result) => {
                if (!cancelled) {
                    setIsLoading(false);
                    setData(result);
                    onResolved(result);
                }
            },
            (err) => {
                if (!cancelled) {
                    setIsLoading(false);
                    setError(err);
                    onRejected(err);
                }
            },
        );

        return () => {
            // Reset state when we make a new request
            cancelled = true;
            setIsLoading(true);
            setData(null);
            setError(null);
        };
        // Disable exhaustive-deps because we cannot statically analyze dependencies array contents.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, refreshCount, ...dependencies]);

    return { isLoading, data, error, refresh };
}

/**
 * React hook for making a GET request. A new request will be made when `url` or any value in
 * `dependencies` changes. The response of any previous request is ignored. `dependencies` should be
 * added to signal when `content` has logically changed. Uses {@link useRequest} with
 * {@link get Rest.get} under the hood.
 * @param url URL to send the GET request to.
 * @param content Content to send with the request.
 * @param dependencies Array of values that will trigger a new request when any of its contents
 * change. Checks for changes using {@link Object.is} on each value.
 */
export function useGetRequest<D = unknown>(
    url: string,
    content?: Record<string, unknown>,
    dependencies: DependencyList = [],
): UseRequestReturn<D, Failed> {
    return useRequest(get, url, content, dependencies);
}

export function formSubmit(form: HTMLFormElement) {
    const method = form.method.toLowerCase() === "get" ? get : post;
    return method(form.action, domForm.toObject(form));
}

interface Response {
    // see RestResponse.java
    status: number;
    success: boolean;
    message?: string;
    data?: any;
    stats?: any;
    isUserFriendlyMessage: boolean;
}

let logoutPollTimeout: number;

export function parseResponse(xhr: XMLHttpRequest) {
    if (!Str.startsWith(xhr.getResponseHeader("Content-Type") || "", "application/json")) {
        // We got served an error page instead of a RestResponse. It's not JSON, so don't try to
        // parse it; just give an error message based on the HTTP status line.
        throw xhr.status
            ? Error("Status " + xhr.status + " " + xhr.statusText)
            : Error("Could not connect to Everlaw servers.");
    }
    const res: Response = JSON.parse(xhr.responseText); // throws SyntaxError on bad/truncated JSON
    res.status = xhr.status;
    res.success = xhr.status === 200;
    return res;
}

// beforeunload events are cancellable, so we may see multiple
// events without actually unloading the page.
let unloadCount = 0;
dojo_on(window, "beforeunload", () => {
    unloadCount++;
});
const navMessage = "_navigating away";

function handleResponse(xhr: XMLHttpRequest, url: string, initialUnloadCount: number): Response {
    // In-flight requests are terminated when navigating away from a page.
    // We should not treat this as an error.
    const isUnloading = initialUnloadCount < unloadCount;
    if (xhr.status === 0 && isUnloading) {
        return {
            status: 0,
            success: false,
            message: navMessage,
            isUserFriendlyMessage: false,
        };
    }
    try {
        return parseResponse(xhr);
    } catch (error) {
        return {
            status: xhr.status,
            success: false,
            data: error,
            message: "Unable to load " + url + ". " + error.message,
            isUserFriendlyMessage: false,
        };
    }
}
function prepareXHR(
    xhr: XMLHttpRequest,
    url: string,
    func: Callback,
    err: (e: Failed) => void,
    retry: () => void,
) {
    const initialUnloadCount = unloadCount;
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.onreadystatechange = function () {
        if (xhr.readyState !== XMLHttpRequest.DONE) {
            return;
        }
        const res = handleResponse(xhr, url, initialUnloadCount);
        if (res.success) {
            if (res.stats) {
                displayStats(res.stats, url);
            }
            func(res.data, res.message, res.stats);
        } else if (!promptIfNeeded(res, retry, fail)) {
            fail();
        }
        function fail() {
            err(new Failed(res.data, res.message, res.status, res.isUserFriendlyMessage));
        }
    };
    setLogoutPollTimeout();
}

interface ReauthParams {
    authMessage: string;
    dialogTitle: string;
    dialogPrompt: string;
    makeDialogBody: (resp: Response, cb: () => void) => Promise<Widget>;
}

class ReauthPrompter {
    public readonly authMessage: string;
    private widget: Widget;
    private dialog: QueryDialog;
    constructor(
        params: ReauthParams,
        private retryCallback: () => void,
        private failCallback: () => void,
        resp: Response,
    ) {
        // When we're making a Reauth Dialog, it's important to close any floating popups
        dojo_topic.publish("close-floating-panels");
        this.authMessage = params.authMessage;
        const body = Dom.div();
        this.dialog = QueryDialog.create({
            title: params.dialogTitle,
            prompt: params.dialogPrompt,
            classes: "reauth-prompter-dialog",
            body: body,
            onSubmit: () => {
                // this should never happen because the buttons are hidden
                return false;
            },
            onHide: () => {
                // When we've hidden the dialog, make sure to destroy everything and
                // If this is after a succesful authentication, any callbacks should
                // already have been called/discarded.
                this.destroy();
            },
            closable: false,
        });
        params
            .makeDialogBody(resp, () => this.success())
            .then((widget) => {
                this.widget = widget;
                Dom.place(this.widget, body);
                this.widget.focus();
            });
        this.dialog.hideLowerDiv(); // widgets should provide their own button
        this.dialog.show();
    }
    destroy() {
        // If we still have callbacks configured, make sure to fail!
        this.fail();
    }
    isActive() {
        return this.dialog && this.dialog.isOpen();
    }
    private success() {
        this.destroyAndExecute(this.retryCallback);
    }
    private fail() {
        this.destroyAndExecute(this.failCallback);
    }
    private destroyAndExecute(callback: () => void) {
        if (this.dialog) {
            this.dialog.hide();
            this.dialog = null;
        }
        this.widget && this.widget.destroy();
        this.retryCallback = null;
        this.failCallback = null;
        // Fire off the callback asynchronously
        if (callback) {
            setTimeout(callback, 0);
        }
    }
}

const loginPrompter: ReauthParams = {
    authMessage: "_needAuth",
    dialogTitle: "Log In",
    dialogPrompt:
        "You have been logged out due to inactivity or expired credentials. Please log back in to continue.",
    makeDialogBody(resp, success): Promise<Widget> {
        return import("Everlaw/LoginDialog").then((LoginDialog) => {
            enableLogoutPoll(false);
            return new LoginDialog.LoginForm({
                onLogin: () => {
                    enableLogoutPoll(true);
                    success();
                },
            });
        });
    },
};

const federalTermsOfUsePrompter: ReauthParams = {
    authMessage: "_needFederalTermsOfUseAck",
    dialogTitle: "Terms Of Use",
    dialogPrompt: "",
    makeDialogBody(resp, success): Promise<Widget> {
        return import("Everlaw/FederalTermsOfUse").then((FederalTermsOfUse) => {
            return new FederalTermsOfUse.TermsOfUseWidget({
                onAgreement: success,
            });
        });
    },
};

const mfaPrompter: ReauthParams = {
    authMessage: "_needMfa",
    dialogTitle: "Authentication required",
    dialogPrompt: "",
    makeDialogBody(resp: Response, success): Promise<Widget> {
        const hasDevice: boolean = resp.data;
        return import("Everlaw/MFA").then((MFA) => {
            if (!hasDevice && !document.hidden) {
                // Make sure the user gets an email code if they don't have a device (and don't have
                // a recent token)
                MFA.sendEmailCode(true);
            }
            return new MFA.AuthPrompt({
                hasDevice: hasDevice,
                onAuthenticate: success,
                fromPrompt: true,
            });
        });
    },
};

let currPrompter: ReauthPrompter;

const reauthPrompters: ReauthParams[] = [loginPrompter, federalTermsOfUsePrompter, mfaPrompter];

function promptIfNeeded(res: Response, retry: () => void, fail: () => void): boolean {
    let toPrompt: ReauthParams = null;
    for (const reauthPrompt of reauthPrompters) {
        if (reauthPrompt.authMessage === res.message) {
            toPrompt = reauthPrompt;
            break;
        }
    }
    if (res.message === "_needTermsOfUseAck") {
        // Special case for users that haven't signed the latest major version of terms (not to be
        // confused with the federal terms of use). Should redirect to termsOfUse.do instead.
        if (Util.onSuperuserPage()) {
            return true;
        }
        const newLocation =
            res.data && res.data.target
                ? "/termsOfUse/termsOfUse.do?t=" + res.data.target
                : "/termsOfUse/termsOfUse.do";
        location.replace(newLocation);
    }
    if (!toPrompt) {
        // The error did not match any of our prompters.
        return false;
    }
    if (
        currPrompter
        && currPrompter.isActive()
        && currPrompter.authMessage === toPrompt.authMessage
    ) {
        // There is already a prompt for the same issue. Leave it open and reject this new request.
        return false;
    }
    if (currPrompter) {
        currPrompter.destroy();
    }
    currPrompter = new ReauthPrompter(toPrompt, retry, fail, res);

    // we want to purge the screen of recommendations here.

    const rec = getActiveRecommendation();
    const curStep = rec?.recommendationChain.currentIdx;
    if (rec && curStep !== undefined && curStep >= 0) {
        rec?.getStep(curStep).deactivate();
    }
    return true;
}

export function displayStats(stats: any, name: string) {
    console.log("Rest request: " + name);
    console.log("Execute time: " + stats.executeTime + "ms");
    console.log(stats.message);
    createRepeatedQueryDialog(stats.repeatedQueries);
}

export interface RepeatedQueryStats {
    endpoint: string;
    queries: { table: string; count: number }[];
}

export function createRepeatedQueryDialog(repeatedQueries?: RepeatedQueryStats) {
    if (!repeatedQueries || repeatedQueries.queries.length === 0) {
        return;
    }
    Dialog.ok(
        "New N+1 Queries Detected",
        Dom.div(
            Dom.p("See RepeatedQueryParser.java."),
            Dom.p("Endpoint: " + repeatedQueries.endpoint),
            Dom.ul(repeatedQueries.queries.map((q) => Dom.li(q.count + ": " + q.table))),
        ),
    );
}

/**
 * Make a POST request on page unload, using the widely supported navigator.sendBeacon method.
 * If that functionality is not present (IE...) then we attempt to make a synchronous XHR request.
 * Note that in either case there is no success/error callback.
 */
export function postOnPageUnload(url: string, content?: any) {
    const data = objectToQuery(Object.assign({ _csrf: dojo_cookie("XSRF-TOKEN") }, content));
    if (navigator && navigator.sendBeacon) {
        // Sending this as a blob with a specified content type is the only way that seems to work
        // as of Chrome 73.
        navigator.sendBeacon(
            url,
            new Blob([data], { type: "application/x-www-form-urlencoded;charset=UTF-8" }),
        );
    } else {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", url, false);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        try {
            xhr.send(data);
        } catch (e) {
            if (e.name !== "NetworkError" && e.name !== "AbortError" && e.name !== "TimeoutError") {
                throw e;
            }
            // Otherwise, the server is most likely down or the user hit stop. There's not much we
            // can really do here.
            // TODO: Consider logging failed POSTs to a fallback, highly-available server.
            console.log(`${e.name} during postOnPageLoad to ${url}`, e);
        }
    }
}

let logoutPollEnabled = true;

export function setLogoutPollTimeout() {
    if (!JSP_PARAMS.User) {
        return;
    }
    if (logoutPollTimeout) {
        clearTimeout(logoutPollTimeout);
    }
    if (!logoutPollEnabled) {
        return;
    }
    // Add an extra second to the timeout to ensure that this poll is after the timeout duration.
    logoutPollTimeout = window.setTimeout(
        () => {
            post("/users/logoutPoll.rest").catch(() => {});
        },
        (JSP_PARAMS.SessionTimeout ? JSP_PARAMS.SessionTimeout * C.SEC : C.MIN * 15) + C.SEC,
    );
}

export function enableLogoutPoll(enabled: boolean = true) {
    if (!enabled && logoutPollTimeout) {
        clearTimeout(logoutPollTimeout);
    }
    logoutPollEnabled = enabled;
    if (enabled) {
        setLogoutPollTimeout();
    }
}

export function startKeepAliveInterval(): number {
    get("/session/keepAlive.rest");
    return window.setInterval(
        () => {
            get("/session/keepAlive.rest");
        },
        (JSP_PARAMS.SessionTimeout * C.SEC) / 3,
    );
}

export function isAuthFailure(e: any): boolean {
    return e instanceof Failed && e.isAuthFailure();
}

/**
 * The global unhandledrejection listener attached by UnhandledPromiseRejectionHandler.ts
 * will show this error b/c it has an attribute named "show" that is a function
 */
export class Failed {
    constructor(
        readonly data: any,
        readonly message: string | undefined,
        readonly status: number,
        readonly isUserFriendlyMessage = false,
    ) {}
    isAuthFailure(): boolean {
        return reauthPrompters.some((authPrompt) => authPrompt.authMessage === this.message);
    }
    show() {
        // Don't show a popup for auth-related errors or for navigation-related in-flight termination
        if (!this.isAuthFailure() && this.message !== navMessage) {
            // We don't use SbFree#inContext here because it would introduce circular dependencies!
            const supportName = JSP_PARAMS.Server.isSbFree ? "Storybuilder" : "Everlaw";
            const body = this.isUserFriendlyMessage
                ? this.message
                : Dom.div(
                      { class: "error-dialog" },
                      Dom.p({ class: "error-instruct" }, "An unexpected error occurred:"),
                      Dom.p({ class: "error-detail" }, this.message),
                      Dom.p(
                          { class: "error-instruct" },
                          `Please contact ${supportName} support for assistance.`,
                      ),
                  );
            Dialog.ok("Error", body, undefined, "456px");
        }
    }
}

/// Temporary hack: In 39.0, we added 99 additional long poll domains: {1-99}.longpoll.everlaw....
/// In order to avoid breaking long-polling for clients using a whitelist-based firewall, requests
/// for those new domains that fail with a 0 status retry using longpoll.everlaw... instead. If the
/// retry succeeds, we use the fallback domain eagerly thereafter.
///
/// Since we report successful fallbacks to Bugsnag, we attempt to minimize false positives by only
/// calling the fallback a success if it succeeds immediately after an original long-poll fails. If
/// that fallback attempt fails, we go back to the original URL. Furthermore, once an original URL
/// has succeeded, we avoid fallbacks thereafter.
enum LongPollFallbackState {
    ORIGINAL_WITH_FALLBACK, // initial state: try original, immediately try fallback on failure
    USE_ORIGINAL, // original longpoll URL succeeded, or it matches fallback URL
    TRYING_FALLBACK, // original URL failed, trying fallback URL once
    USE_FALLBACK, // original URL failed but fallback succeeded
}

let longPollFallbackState = LongPollFallbackState.ORIGINAL_WITH_FALLBACK;

const LONGPOLL_RETRY = /\/\/[1-9][0-9]?\.longpoll\./;
const LONGPOLL_FALLBACK = "//longpoll.";

/**
 * Starts a long-polling Rest request.
 *
 * @param.url       the URL to connect to, typically ending in ".rest"
 * @param.content   the data to send, which the backend will receive as RequestParam parameters
 * @param.success   a function(data, msg) that is called upon receiving a success response;
 *                  typically the function should start the request again
 */
export function longPoll(params: {
    url: string;
    content: any;
    onResponse(r: Response): void;
    onError?(): void;
}) {
    // dojo/request works poorly for long-polling in two ways:
    // 1) It attaches a progress eventlistener to the XHR object, which makes Firefox show the
    //    page as still being loaded if the request is started before everything else has loaded
    // 2) It logs errors to the console, but a long-polling request will always fail when
    //    navigating away from the page; this is normal and does not warrant console spam
    const xhr = new XMLHttpRequest();
    function doRequest() {
        // Fallback logic; see explanation above.
        const url =
            longPollFallbackState === LongPollFallbackState.TRYING_FALLBACK
            || longPollFallbackState === LongPollFallbackState.USE_FALLBACK
                ? params.url.replace(LONGPOLL_RETRY, LONGPOLL_FALLBACK)
                : params.url;
        xhr.open("GET", url + "?" + objectToQuery(params.content));
        /* This header would force browsers to "pre-flight" the request with an OPTIONS request to
         * check cross-domain accessibility, which would take some work to handle in Spring.
         * Fortunately we don't actually need the header here (it's for RedirectUtil#restAwareRedirect,
         * and our only long-polling request, poll.rest, should never redirect) */
        // xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.send();
    }
    xhr.onreadystatechange = function () {
        if (xhr.readyState !== XMLHttpRequest.DONE) {
            return;
        }
        let res: Response;
        try {
            res = parseResponse(xhr);
        } catch (e) {
            if (xhr.status === 0) {
                // Fallback logic; see explanation above. When we have an eligible fallback to try,
                // we try it once immediately without delay. If it fails again, we instead fall
                // through to standard error handling.
                if (longPollFallbackState === LongPollFallbackState.ORIGINAL_WITH_FALLBACK) {
                    if (params.url.replace(LONGPOLL_RETRY, LONGPOLL_FALLBACK) === params.url) {
                        // original URL is the same as the fallback URL
                        longPollFallbackState = LongPollFallbackState.USE_ORIGINAL;
                    } else {
                        longPollFallbackState = LongPollFallbackState.TRYING_FALLBACK;
                        doRequest();
                        return;
                    }
                } else if (longPollFallbackState === LongPollFallbackState.TRYING_FALLBACK) {
                    // fallback failed too, bounce back to original state and retry after a delay
                    longPollFallbackState = LongPollFallbackState.ORIGINAL_WITH_FALLBACK;
                }
            }
            // Most failures are caused when the server is temporarily unreachable. Since this can
            // happen during a server restart, we wait about half as long as the typical restart
            // time before trying again.
            console.log("Request failed:", e.message);
            setTimeout(doRequest, 30 * C.SEC);
            params.onError && params.onError();
            return;
        }
        if (longPollFallbackState === LongPollFallbackState.ORIGINAL_WITH_FALLBACK) {
            // Original URL succeeded.
            longPollFallbackState = LongPollFallbackState.USE_ORIGINAL;
        } else if (longPollFallbackState === LongPollFallbackState.TRYING_FALLBACK) {
            // On fallback success we eagerly fall back thereafter.
            longPollFallbackState = LongPollFallbackState.USE_FALLBACK;
        }
        params.onResponse(res);
    };
    doRequest();
    return xhr;
}

export function longPollStop(xhr: XMLHttpRequest) {
    xhr.onreadystatechange = null;
    xhr.abort();
}

export function uploadFile(url: string, form: HTMLFormElement | FormData, content: any = {}) {
    return new Promise<any>(function retry(resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        xhr.setRequestHeader("X-XSRF-TOKEN", dojo_cookie("XSRF-TOKEN"));
        prepareXHR(xhr, url, resolve, reject, () => {
            retry(resolve, reject);
        });
        const formData = form instanceof HTMLFormElement ? new FormData(form) : form;
        Object.keys(content).forEach((k) => {
            const value = content[k];
            if (Is.defined(value)) {
                formData.append(k, value);
            }
        });
        xhr.send(formData);
    });
}

// Start the logout poll. This will check if the user is logged out.
setLogoutPollTimeout();
